<template>
  <v-row align="end" class="fill-height" justify="center" no-gutters>
    <v-card class="text-center" color="grey lighten-4" flat tile width="100%">
      <v-card-text class="my-3">
        <b>{{ $vuetify.lang.t('$vuetify.pages.auth.contactSupport') }}</b>
        <br v-if="!$vuetify.breakpoint.smAndUp">
        <v-icon class="ms-3" color="secondary">mdi-email-outline</v-icon>
        <a class="ms-2" href="mailto:VendorSupport@kfi.com">VendorSupport@kfi.com</a>
        <br v-if="!$vuetify.breakpoint.smAndUp">
        <v-icon class="ms-2" color="secondary">mdi-phone-in-talk-outline</v-icon>
        <span class="mx-1">123-4567890123</span>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: 'KurccSupportInfo'
}
</script>
